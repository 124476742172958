import { useEffect, useState } from "react";
import { IconCards } from "./icons/IconCards";
import { IconCardsOutline } from "./icons/IconCardsOutline";
import usePersistentStore from "../lib/store/PersistentStore";
import { gridLevelDateKey } from "../lib/utils/gridLevels";

export function PromoBanner() {
  const [cardMode, toggleCardMode] = usePersistentStore((state) => [
    state.cardMode,
    state.toggleCardMode,
  ]);

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (cardMode) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [cardMode]);

  function toggleMode() {
    toggleCardMode();
  }

  return (
    ["2024-01-02","2024-01-03"].includes(gridLevelDateKey) && (
      <div className="w-screen bg-yellow-100 p-2 px-1 text-center text-sm text-gray-800 sm:text-sm md:p-3">
        📹 See who <a
          className="font-bold text-blue-600 underline dark:text-blue-400"
          href="https://twitter.com/PokegridGame/status/1742339799019839995"
          target="_blank"
        >
          Stremi TV
        </a> picked in his self-designed PokeGrid!
      </div>
      // <div className="w-screen bg-yellow-100 p-2 px-0 text-center text-sm text-gray-800 sm:text-sm md:p-3">
      //   Have your say on{" "}
      //   <a
      //     className="font-bold text-blue-600 underline dark:text-blue-400"
      //     href="https://twitter.com/PokegridGame/status/1734206880925250028"
      //     target="_blank"
      //   >
      //     which category we should add next?
      //   </a>{" "}
      // </div>
    )
  );
}
