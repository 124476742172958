import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import useGameStore from "../../lib/store/GameStore";
import { Footer } from "../Footer";
import { PromoBanner } from "../PromoBanner";
import { shallow } from "zustand/shallow";
import ResultDialog from "../modals/ResultDialog";
import PickHistoryDialog from "../modals/PickHistoryDialog";
import HowToPlayDialog from "../modals/HowToPlayDialog";
import LoginDialog from "../modals/LoginDialog";
import { HeaderIcons } from "../HeaderIcons";
import "react-toastify/dist/ReactToastify.css";
import PromoDialog from "../modals/PromoDialog";
import Link from "next/link";
import GameHistoryDialog from "../modals/GameHistoryDialog";

function BasePage(props) {
  const [initError, setupLevel] = useGameStore(
    (state) => [state.initError, state.setupLevel],
    shallow
  );

  const [adsLoaded, setAdsLoaded] = useState(false);

  const init = async () => {
    setupLevel();
  };

  // Set up game
  useEffect(async () => {
    await init();

    if (!adsLoaded) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      setAdsLoaded(true);
    }
  }, []);

  return (
    <div className="relative bg-slate-100">
      <PromoBanner />
      <header className="border-b border-b-slate-300 bg-white text-center">
        <div className="mx-auto flex max-w-screen-lg items-center justify-between px-4 py-2.5 sm:px-6 sm:py-3.5">
          <Link href="/" passHref>
            <a className="image-block-hack inline-flex items-center justify-center">
              <img
                className="h-9 w-9 sm:my-0 sm:h-10 sm:w-10"
                src="img/pokegrid-ball.png"
                alt="PokeGrid Logo"
              ></img>
              <span className="ml-2 text-xl font-bold text-gray-800">
                PokeGrid<span className="inline-block">.net</span>
              </span>
            </a>
          </Link>

          <HeaderIcons />
        </div>
      </header>

      {props.children}

      <Footer />
      <ResultDialog />
      <PickHistoryDialog />
      <GameHistoryDialog />
      <HowToPlayDialog />
      <LoginDialog />
      <MyToastContainer />
      {/* <PromoDialog /> */}
    </div>
  );
}

export default BasePage;

function MyToastContainer() {
  const contextClass = {
    success: "bg-blue-600",
    error: "bg-red-600",
    info: "bg-gray-600",
    warning: "bg-orange-400",
    default: "bg-indigo-600",
    dark: "bg-white-600 font-gray-300",
  };

  return (
    <ToastContainer
      bodyClassName="font-sans text-sm text-gray-800"
      autoClose={3000}
    />
  );
}
